@use 'abstracts' as *;

cad-menu-item {
  height: 3rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--color-black-80);

  &:not(.header):hover {
    cursor: pointer;
    background: var(--color-white-20);
  }

  .menu-item__icon {
    width: 3rem;
    font-size: 1rem;
    color: var(--color-black-80);
    text-align: center;
  }
  .menu-item__text {
    padding: 0 1rem 0 0.5rem;
  }

  &.disabled{
    cursor: default;
    color: clr(grayscale-100);
  }

  &.warn {
    .menu-item__icon {
      color: var(--color-red-80);
    }

    color: var(--color-red-80);

    &:not(.header):hover {
      cursor: pointer;
      background: var(--color-red-20);
    }
  }

  &.disabled {
    pointer-events: none;

    .menu-item__icon {
      color: clr(grayscale-300);
    }

    color: clr(grayscale-300);
  }

  .cad-avatar {
    margin-inline: size(8);
  }
}
